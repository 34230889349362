function compareRoutes(routeA: string, routeB: string) {
  return routeA.length > routeB.length ? routeA.includes(routeB) : routeB.includes(routeA)
}
export const resolveNavigateFromRoute = (currentRouteName?: string) => {
  const router = useRouter()

  const { history } = router.options

  const previousRoute = history.state.back || ''
  const currentRoute = history.state.current || ''

  if (compareRoutes(previousRoute as string, currentRoute as string)) {
    return currentRouteName as string
  }

  if (!currentRouteName || previousRoute?.toString()?.includes(currentRouteName as string)) {
    return previousRoute as string
  }

  return currentRouteName as string
}

export const useGetForwardRoute = () => {
  const router = useRouter()

  const { history } = router.options

  return history.state.forward as string
}

export const useGetCurrentRoute = () => {
  const router = useRouter()

  const { history } = router.options

  return history.state.current as string
}

export const useGetBackRoute = () => {
  const router = useRouter()

  const { history } = router.options

  return history.state.back as string
}
