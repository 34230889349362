<template>
  <div class="page-header">
    <div class="home d-flex">
      <CommonBaseImg
        class="wrapper-tooltip__icon"
        src="/assets/images/components/mobile/icon-back.svg"
        alt="icon tooltip"
        @click="onBack()"
      />
      <div class="title">{{ title }}</div>
    </div>
    <div v-if="guidePath" class="guide" @click="router.push(guidePath)">Hướng dẫn</div>
    <div v-if="isShowTooltip" v-click-outside="onClickOutSideTooltip" class="wrapper-tooltip">
      <CommonBaseImg
        class="wrapper-tooltip__icon"
        src="/assets/images/help.svg"
        alt="icon tooltip"
        @click="handleTooltip()"
      />
      <div v-if="isShowContentTooltip" class="wrapper-tooltip__text d-flex">
        <span class="icon icon-warning-black" />
        <div class="wrapper-tooltip__content">
          <div class="heading">{{ dataTooltip?.heading }}</div>
          <div class="sub-text">{{ dataTooltip?.subText }}</div>
        </div>
      </div>
    </div>
    <div v-if="showHomeFromPage" class="home">
      <CommonBaseImg
        class="wrapper-tooltip__icon icon-home"
        src="/assets/images/icon-home.svg"
        alt="icon tooltip"
        @click="goHomeFromPage()"
      />
    </div>
    <div v-if="showHome" class="home">
      <CommonBaseImg
        class="wrapper-tooltip__icon icon-home"
        src="/assets/images/icon-home.svg"
        alt="icon tooltip"
        @click="goHome()"
      />
    </div>
    <div v-if="showClose" class="home">
      <CommonBaseImg
        class="wrapper-tooltip__icon"
        src="/assets/images/components/mobile/icon-close.svg"
        alt="icon tooltip"
        @click="closeMenu()"
      />
    </div>
    <div v-if="showFilter" class="home">
      <CommonBaseImg
        src="/assets/images/icon-filter-schedule.svg"
        alt="icon-filter"
        class="icon-filter"
        @click="actionFilter"
      />
    </div>
    <client-only>
      <div v-if="showAuthen" class="header-button">
        <UserNotLogin v-if="!currentUser" />
        <UserLogged v-else />
      </div>
    </client-only>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import UserNotLogin from '~/components/mobile/header/user-not-login.vue'
import UserLogged from '~/components/mobile/header/user-logged.vue'
import CommonBaseImg from '~/components/common/base-img.vue'
import { useAppStore } from '~/store/app'
import { usePromotionStore } from '~/store/promotion'
import { PAGE_URLS, ACCOUNT_URLS } from '~/config/page-url'
import { useToggleMenu } from '~/composables/useToggleMenu'
const emit = defineEmits(['filter'])
const { $pinia } = useNuxtApp()
const appstore = useAppStore($pinia)
const { $state } = useAppStore($pinia)
const store = usePromotionStore($pinia)
const { openDepositFrom } = storeToRefs(appstore)
const { setDepositFrom } = appstore
const route = useRoute()
const router = useRouter()
const { toggleMenuAccount, toggleMenuDeposit, toggleMenuWithdraw } = useToggleMenu()
const currentUser = computed(() => {
  return $state.currentUser
})

interface IProps {
  title: string
  pathBack: string
  guidePath: string
  isReplacePathBack: boolean
  showAuthen: boolean
  isShowTooltip: boolean
  showHome: boolean
  showClose: boolean
  showFilter: boolean
  fromDeposit: boolean
  backBrowser: boolean
  fromWithdraw: boolean
  showHomeFromPage: boolean
  dataTooltip?: {
    heading?: string
    subText: string
  }
}
const props = withDefaults(defineProps<IProps>(), {
  title: '',
  pathBack: '',
  guidePath: '',
  isReplacePathBack: false,
  showAuthen: false,
  isShowTooltip: false,
  showHome: false,
  showClose: false,
  showFilter: false,
  fromDeposit: false,
  backBrowser: false,
  showHomeFromPage: false,
  fromWithdraw: false,
  dataTooltip: undefined
})

const isShowContentTooltip = ref(false)
const handleTooltip = () => {
  isShowContentTooltip.value = !isShowContentTooltip.value
}
const onClickOutSideTooltip = () => {
  isShowContentTooltip.value = false
}
const onBack = async () => {
  if (props.backBrowser) {
    if (window.history.length === 2) {
      if (route?.name === 'khuyen-mai-id' || route?.name === 'khuyen-mai-vip') {
        router.push(PAGE_URLS.PROMOTION)
        return
      }
      if (route?.name === 'tin-tuc-alias') {
        router.push(PAGE_URLS.NEWS)
        return
      }
      if (route?.name === 'gioi-thieu-alias' || route?.name === 'huong-dan-alias') {
        router.push(PAGE_URLS.HOME)
        return
      }
    }
    router.back()
    return
  }
  if (
    openDepositFrom.value === 'home' &&
    (route.path === PAGE_URLS.HOME ||
      route.name === 'casino' ||
      route.name === 'games' ||
      route.name === 'games-lottery')
  ) {
    toggleMenuDeposit()
    setDepositFrom('')
    return
  }

  if (props.fromDeposit) {
    toggleMenuDeposit()
    toggleMenuAccount()
    return
  }
  if (props.fromWithdraw) {
    toggleMenuWithdraw()
    toggleMenuAccount()
    return
  }

  if (
    route.path?.includes(ACCOUNT_URLS.DEPOSIT) ||
    (route.path?.includes(ACCOUNT_URLS.P2P) && route.query?.type === 'buy')
  ) {
    await navigateTo(resolveNavigateFromRoute() || '/')
    setTimeout(() => {
      toggleMenuDeposit()
    }, 200)
  } else if (
    route.path?.includes(ACCOUNT_URLS.WITHDRAW) ||
    (route.path?.includes(ACCOUNT_URLS.P2P) && route.query?.type === 'sell')
  ) {
    await navigateTo(resolveNavigateFromRoute() || '/')
    setTimeout(() => {
      toggleMenuWithdraw()
    }, 200)
  } else if (
    route.path?.includes(ACCOUNT_URLS.P2P) ||
    route.path?.includes(ACCOUNT_URLS.TRANSACTION_HISTORY) ||
    route.path?.includes(ACCOUNT_URLS.BET_HISTORY) ||
    route.path?.includes(ACCOUNT_URLS.PROFIT_TODAY) ||
    route.path?.includes(ACCOUNT_URLS.BANK) ||
    route.path?.includes(ACCOUNT_URLS.PROFILE)
  ) {
    await navigateTo(resolveNavigateFromRoute() || '/')
    setTimeout(() => {
      toggleMenuAccount()
    }, 200)
  } else if (props.pathBack) {
    if (props.isReplacePathBack) {
      router.replace(props.pathBack)
    } else {
      router.push(props.pathBack)
    }
  } else {
    handleCategoryActive()
  }
}

const handleCategoryActive = () => {
  const prevCate = store.prevCategoryActive || {}
  if (prevCate.indexList?.length) {
    const prevIndex = prevCate.indexList[prevCate.indexList.length - 1]
    setTimeout(() => {
      const elementList = document.getElementsByClassName(prevCate.classNameList)
      const elementItem = document.getElementsByClassName(prevCate.classNameItem)
      const wrap = elementList?.length ? elementList[0] : null
      const item = elementItem?.length ? elementItem[prevIndex] : null
      if (item && wrap) {
        const position = (item as any).offsetLeft - (wrap as any).offsetWidth / 2 + (item as any).offsetWidth / 2
        wrap.scrollTo({ left: position, behavior: 'smooth' })
        const indexList = prevCate.indexList.slice(0, prevCate.indexList.length - 1)

        store.setPrevCategoryActive({
          indexList,
          classNameList: prevCate.classNameList,
          classNameItem: prevCate.classNameItem
        })
      }
    }, 100)
  }
}
watch(isShowContentTooltip, () => {
  if (isShowContentTooltip.value) {
    setTimeout(() => {
      isShowContentTooltip.value = false
    }, 2000)
  }
})
const goHomeFromPage = () => {
  router.push({ path: PAGE_URLS.HOME })
  if (props.fromWithdraw) {
    toggleMenuWithdraw()
  }
  setDepositFrom('')
  if (props.fromDeposit) {
    toggleMenuDeposit()
  }
}
const goHome = () => {
  router.push({ path: PAGE_URLS.HOME })
  toggleMenuDeposit()
  setDepositFrom('')
}
const closeMenu = () => {
  setDepositFrom('')
  if (props.fromDeposit) {
    toggleMenuDeposit()
    return
  }
  if (props.fromWithdraw) {
    toggleMenuWithdraw()
  }
}

const actionFilter = () => {
  emit('filter')
}

onMounted(() => {
  window.addEventListener('scroll', function () {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      // Scroll to calc the viewport
      window.scrollBy(0, -0.1)
    }
  })
})
</script>
<style src="assets/scss/components/mobile/common/page-header/index.scss" scoped lang="scss" />
